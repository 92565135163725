import React, { useState } from 'react';
import { node } from 'prop-types';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { styled } from 'styletron-react';
import Headroom from 'react-headroom';

import { FiHome, FiPaperclip, FiMail } from 'react-icons/fi';

import favicon from '../assets/favicon.ico';

import 'typeface-fira-code';
import 'typeface-varela-round';
import 'typeface-crimson-text';

import '../layouts/index.css';

const mobileMediaQuery = '@media screen and (max-width: 550px)';

const Nav = styled('nav', props => ({
  fontSize: props.pinned ? '.6rem' : '1rem',
  [mobileMediaQuery]: {
    display: 'none',
  },
}));

const MobileNav = styled('nav', {
  display: 'none',
  [mobileMediaQuery]: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    background: '#21272d',
    width: '100%',
    display: 'flex',
    height: '3rem',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

const NavLink = styled(Link, {
  marginLeft: '.5rem',
  color: 'rgba(255,255,255,.8)',
  position: 'relative',
  textDecoration: 'none',
  textShadow: 'none',
  backgroundImage: 'none',
  ':hover': {
    color: 'rgba(255,255,255,.9)',
  },
  [mobileMediaQuery]: {
    margin: 0,
  },
});

const Logo = styled('span', {
  fontSize: '1em',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '.2em',
  [mobileMediaQuery]: {
    fontSize: '1rem',
    height: '2.8em',
    width: '2.8em',
  },
});

const Heading = styled('h3', props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: '46rem',
  padding: props.pinned ? '1rem 1.0875rem' : '3rem 1.0875rem',
  fontSize: props.pinned ? '1rem' : '1.6rem',
  [mobileMediaQuery]: {
    padding: '.4rem',
    justifyContent: 'center',
  },
}));

const HeaderContainer = styled('div', {
  background: '#21272d',
  marginBottom: '1.45rem',
});

const Sub = styled('span', {
  marginLeft: '.4em',
  color: 'rgba(255,255,255,.8)',
  bottom: 0,
  [mobileMediaQuery]: {
    display: 'none',
  },
});

const Header = () => {
  const [pinned, setPinned] = useState(false);

  return (
    <>
      <Headroom onPin={() => setPinned(true)} onUnfix={() => setPinned(false)}>
        <HeaderContainer>
          <Heading pinned={pinned}>
            <Link
              to="/"
              style={{
                color: 'white',
                textDecoration: 'none',
                textShadow: 'none',
                backgroundImage: 'none',
              }}
            >
              <Logo>KDE</Logo>
              <Sub>Design</Sub>
            </Link>
            <Nav>
              <NavLink to="/about">Me</NavLink>
              <NavLink to="/blog">Blog</NavLink>
              <NavLink to="/contact">Contact</NavLink>
            </Nav>
          </Heading>
        </HeaderContainer>
      </Headroom>
      <MobileNav>
        <NavLink to="/about">
          <FiHome />
        </NavLink>
        <NavLink to="/blog">
          <FiPaperclip />
        </NavLink>
        <NavLink to="/contact">
          <FiMail />
        </NavLink>
      </MobileNav>
    </>
  );
};

const Container = styled('div', {
  margin: '0 auto',
  maxWidth: '46rem',
  padding: '0px 1.0875rem 1.45rem',
  paddingTop: 0,
  '@media screen and (max-width: 550px)': {
    padding: '0 1rem 4rem',
  },
});

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="KDE Design"
      meta={[
        {
          name: 'description',
          content:
            'KDE Design - Daniel Kovacs, UI / UX Engineer and Web Developer personal site and blog about JavaScript, GoLang, Node JS',
        },
        {
          name: 'keywords',
          content:
            'programming, blog, freelance, design, ui, ux, work, java, javascript, nodejs, html, css',
        },
        { name: 'twitter:card', value: 'summary' },
        { property: 'og:url', content: 'https://kde.design/' },
        {
          property: 'og:description',
          content:
            'KDE Design - Daniel Kovacs, UI / UX Engineer and Web Developer personal site and blog about JavaScript, GoLang, Node JS',
        },
      ]}
    >
      <html lang="en" />
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
    <Header />
    <Container>{children}</Container>
  </div>
);

TemplateWrapper.propTypes = {
  children: node.isRequired,
};

export default TemplateWrapper;
